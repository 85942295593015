import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
axios.defaults.withCredentials = true

// instance.interceptors.request.use(
//     function(config) {
//       const accessToken = localStorage.getItem("access-token");
//       const tokenType = localStorage.getItem("token-type");
//       const expiry = localStorage.getItem("expiry");
//       const client = localStorage.getItem("client");
//       const uid = localStorage.getItem("uid");
//       if (accessToken && client) {
//         config.headers["access-token"] = accessToken;
//         config.headers["token-type"] = tokenType;
//         config.headers["expiry"] = expiry;
//         config.headers["client"] = client;
//         config.headers["uid"] = uid;
//       }
//       return config;
//     },
//     function(error) {
//       return Promise.reject(error);
//     }
//   );


export default instance;
