import React, { useState } from 'react'
// import axios from 'axios'
import axios from '../utils/axios'
import { Button, Form, FormControl } from 'react-bootstrap';
import LoadingSpinner from './LoadingSpinner'
import Stop from './Stop'

export default function NextArrival() {
    const [arrivals, setArrivals] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');
    const [search, setSearch] = useState(false);

    const [stop, setStop] = useState('');
    const handleSubmit = e => {
        e.preventDefault()
        setLoad(false)
        findNextArrivals(stop)
        setSearch(true)
      }
    const inputFields = <Form inline onSubmit={e => handleSubmit(e)}>
                            <FormControl type="text" placeholder="Paradero ej: PA433" className="mr-sm-2" onChange={e => setStop(e.target.value)} />
                            <Button type='submit' variant="outline-success">Buscar</Button>
                        </Form>;
    
    const findNextArrivals = (stop) => {
        axios.get(`/api/v1/red/stops/${stop}`)
        .then(res => {
            console.log(res)
            setArrivals(res.data);
            setLoad(true);
            setSearch(false)
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
    };

    const stopResults = () => {

        if (load) {
            return (<div>
                {error ? <li>{error.message}</li> : <Stop stop={arrivals}  />}
            </div>);
        } else {
            return (
                <div>{search ? <LoadingSpinner /> : null}</div>
            );
        }

    }

    return <div>
        {inputFields}
        <br />
        {stopResults()}
    </div>
    
}
