import React from 'react';
import NextArrival from './components/NextArrival';
function App() {
  
  return <div className='container'>
    <br />
    <h1>Frecuencia de buses por paradero</h1>
    <br />
    <NextArrival />
    </div>
  
}

export default App;
