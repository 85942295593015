import React from 'react'
import { Card } from 'react-bootstrap'
import moment from 'moment'
import 'moment/locale/es'


export default function Stop(props) {
    const { stop } = props;
    moment.locale('es')
    return (
        <div>
            <h1>{stop.id}: {stop.name}</h1>
            <h3>{stop.status_description}</h3>
            {stop.services.map((service, index) => {
                return (
                    <Card key={index} style={{marginTop: '15px'}}>
                        <Card.Body>
                            <Card.Title>{service.id}</Card.Title>
                            <Card.Text>
                                <span>{service.status_description}</span>
                            </Card.Text>
                            <ul>
                                {service.buses.map((bus, index) => {
                                    return (
                                        <li key={index}>{bus.id} a {bus.meters_distance}m en {((bus.min_arrival_time + bus.max_arrival_time)/2)} minutos</li>
                                    )
                                })}
                            </ul>
                        </Card.Body>
                    </Card>
                )
            })}
        </div>
    )
}
